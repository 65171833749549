import { Theme, css } from '@emotion/react'
import styled from '@emotion/styled'

export const menuItemStyle = (theme: Theme) =>
    css({
        fontStyle: 'normal',
        fontSize: theme.spacing(1.8),
        fontWeight: 600,
        padding: theme.spacing(1),
        color: theme.color.brand.secondary[900],
        ':hover': {
            background: theme.color.brand.primary[100],
            padding: theme.spacing(1),
            transition: 'all ease-in-out 500ms',
            borderRadius: theme.spacing(1),
        },
    })

export const menuItemActiveStyle = (theme: Theme) =>
    css({
        //fontWeight: 700,
        background: theme.color.brand.primary[100],
        padding: theme.spacing(1),
        transition: 'all ease-in-out 500ms',
        borderRadius: theme.spacing(1),
    })

export const MenuButton = styled.button((props) => ({
    display: 'none',

    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        padding: 0,
        margin: 0,
        background: 'none',
        border: 'none',
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        marginLeft: props.theme.spacing(1),
        '& svg': {
            padding: 0,
            margin: 0,
            color: '#000000',
            height: 32,
            width: 32,
        },
    },
}))

interface MobileToolbarProps {
    isOpen: boolean
}

export const MobileToolbarStyled = styled.div<MobileToolbarProps>((props) => ({
    display: 'none',
    visibility: 'hidden',
    transition: 'all 0.5s ease',
    height: 0,
    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        visibility: props.isOpen ? 'visible' : 'hidden',
        display: props.isOpen ? 'flex' : 'none',
        flexDirection: 'column',
        height: props.isOpen ? 8 * 25 : 0,
        padding: props.theme.spacing(2),
        '& a': {
            textDecoration: 'none',
        },
        listStyle: 'none',
        '& li': {
            padding: props.theme.spacing(1, 0),
        },
    },
}))

export const BannerStyled = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    height: '400px',
    justifyContent: 'space-between',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        flexDirection: 'column-reverse',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'auto',
    },
}))

export const LangFlagWrapper = styled.div((props) => ({
    display: 'flex',
    marginLeft: props.theme.spacing(2),
    backgroundColor: props.theme.color.neutral.slate[300],
    padding: props.theme.spacing(1),
    borderRadius: props.theme.spacing(0.5),
    cursor: 'pointer',
    alignItems: 'center',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        display: 'none',
    },

    span: {
        fontSize: 'initial',
        fontWeight: 'bold',
    },
}))

export const LangFlag = styled.img((props) => ({
    height: props.theme.spacing(2),
    margin: 0,
    marginLeft: props.theme.spacing(1),
}))

export const BannerDescriptionStyled = styled.div((props) => ({
    paddingRight: '160px',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        paddingRight: 0,
        textAlign: 'center',

        a: {
            margin: 'auto',
        },
    },
}))

export const BannerIllustrationStyled = styled.div((props) => ({
    minWidth: '320px',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        minWidth: 'initial',
        marginBottom: props.theme.spacing(4),
    },
}))

export const BannerIllustrationImageStyled = styled.img({
    display: 'block',
    margin: 0,
})
