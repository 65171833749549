import { Link, graphql, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-react-intl'
import { FC } from 'react'

import Logolight from '../../images/Logo-light.svg'
import { useIntlLabel } from '../../intl'
import Container from '../Container'
import FooterSection from './FooterSection'
import { FooterGlobal } from './__generated__/FooterGlobal'
import {
    AdiotionalLinkStyled,
    ContainerStyled,
    ContentStyled,
    CopyrightStyled,
    FooterStyled,
    ImageStyled,
    ListContainerStyled,
} from './styles'

const Footer: FC = ({ children }) => {
    const intl = useIntl()
    const getIntlLabel = useIntlLabel()
    const { strapiGlobal, allStrapiServiceCategory } = useStaticQuery<FooterGlobal>(
        graphql`
            query FooterGlobal {
                strapiGlobal {
                    releaseVersion
                    releaseVersionUrl
                    siteLogo {
                        localFile {
                            publicURL
                            id
                        }
                    }
                    footerLinks {
                        title
                        title_mg
                        links {
                            label
                            label_mg
                            iconPosition
                            isExternal
                            url
                            icon {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                    LogoUGDPropulsed {
                        localFile {
                            publicURL
                        }
                    }
                }
                allStrapiServiceCategory {
                    nodes {
                        categoryName
                        categoryName_mg
                        icon {
                            localFile {
                                url
                            }
                        }
                    }
                }
            }
        `,
    )

    return (
        <FooterStyled>
            <Container>
                <ContainerStyled>
                    <ImageStyled src={Logolight} alt="TOROLALANA TEXT LOGO" />
                    <ListContainerStyled>
                        {strapiGlobal?.footerLinks?.map((footerLink) => (
                            <FooterSection
                                key={footerLink?.title}
                                footerLink={footerLink}
                                categoryLink={allStrapiServiceCategory.nodes}
                            />
                        ))}
                    </ListContainerStyled>

                    <ContentStyled>
                        <CopyrightStyled>
                            Copyright © {new Date().getFullYear()}, torolalana.gov.mg -{' '}
                            <Link
                                to={strapiGlobal?.releaseVersionUrl ?? '#'}
                                style={{ color: 'inherit', textDecoration: 'none' }}
                                target="_blank"
                            >
                                {strapiGlobal?.releaseVersion}
                            </Link>
                        </CopyrightStyled>
                        <AdiotionalLinkStyled>
                            <Link
                                to="https://digital.gov.mg"
                                target="_blank"
                                style={{ color: 'inherit', textDecoration: 'none' }}
                            >
                                {intl.formatMessage({ id: 'site_powered_by_label' })} Unité de
                                Gouvernance Digitale
                            </Link>
                        </AdiotionalLinkStyled>
                    </ContentStyled>
                </ContainerStyled>
            </Container>
        </FooterStyled>
    )
}

export default Footer
