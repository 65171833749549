import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import * as React from 'react'

interface IProps {
    toggle?: () => void
    openMenu: boolean
}

const MenuMotionContainerStyled = styled(motion.div)((props) => ({
    display: 'none',
    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        display: 'block',
    },
}))

const MenuButton = styled.button((props) => ({
    display: 'none',

    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        outline: 'none',
        width: '40px',
        height: '40px',

        padding: 0,
        margin: 0,
        background: 'none',
        border: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        cursor: 'pointer',
        marginLeft: props.theme.spacing(1),
        '& svg': {
            padding: 0,
            margin: 0,
            color: '#000000',
            height: 32,
            width: 32,
        },
    },
}))

const Path = (props: any) => (
    <motion.path
        fill="transparent"
        strokeWidth="2"
        stroke="hsl(0, 0%, 18%)"
        strokeLinecap="round"
        {...props}
    />
)

export const MenuToggle: React.FC<IProps> = ({ toggle, openMenu }) => (
    <MenuMotionContainerStyled animate={openMenu ? 'open' : 'closed'}>
        <MenuButton onClick={toggle}>
            <svg width="23" height="23" viewBox="0 0 23 23">
                <Path
                    variants={{
                        closed: { d: 'M 2 2.5 L 20 2.5' },
                        open: { d: 'M 3 16.5 L 17 2.5' },
                    }}
                />
                <Path
                    d="M 2 9.423 L 20 9.423"
                    variants={{
                        closed: { opacity: 1 },
                        open: { opacity: 0 },
                    }}
                    transition={{ duration: 0.1 }}
                />
                <Path
                    variants={{
                        closed: { d: 'M 2 16.346 L 20 16.346' },
                        open: { d: 'M 3 2.5 L 17 16.346' },
                    }}
                />
            </svg>
        </MenuButton>
    </MenuMotionContainerStyled>
)
