import styled from '@emotion/styled'
import { useLocation } from '@reach/router'
import { motion } from 'framer-motion'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, changeLocale, useIntl } from 'gatsby-plugin-react-intl'
import React, { FC, useEffect, useState } from 'react'

import Logo from '../../images/Logo.svg'
import { useIntlLabel } from '../../intl'
import Container from '../Container'
import { MenuToggle } from './MenuToggle'
import { HeaderGlobal } from './__generated__/HeaderGlobal'
import {
    LangFlag,
    LangFlagWrapper,
    MobileToolbarStyled,
    menuItemActiveStyle,
    menuItemStyle,
} from './styles'

interface IHeaderProps {
    isHome: boolean
}

const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: '-100%' },
}

interface IHeaderStyledProps {
    isSticky: boolean
}

const HeaderStyled = styled.header<IHeaderStyledProps>((props) => ({
    fontFamily: props.theme.fonts.default,
    width: '100%',
}))

const ToolbarStyled = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100px',
    '@media (max-width: 768px)': {
        height: '72px',
    },
})

const ImageStyled = styled.img({
    margin: '0 8px 0 0',
    height: 40,
    '@media (max-width: 768px)': {
        width: '72px',
    },
})

const Placeholder = styled.div((props) => ({
    flexGrow: 1,
    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        flexGrow: 1,
    },
}))

const MenuWrapper = styled.ul((props) => ({
    //flexGrow: 1,
    margin: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    fontStyle: 'normal',
    listStyle: 'none',
    '& a': {
        textDecoration: 'none',
    },

    [props.theme.breakpoints.down(props.theme.breakpoints.medium)]: {
        display: 'none',
    },
}))

const MenuListElement = styled.li(({ theme }) => ({
    fontFamily: theme.fonts.default,
    fontWeight: 600,
    margin: 0,
    padding: theme.spacing(1),
}))

const Header: FC<IHeaderProps> = ({ isHome }) => {
    const [isSticky, setSticky] = useState(false)
    const [openMenu, setOpenMenu] = React.useState(false)
    const [preferedLanguage, setPreferedLanguage] = React.useState<string | null>('')

    const intl = useIntl()
    const location = useLocation()
    const currentRoute = location.pathname.replace(/\/$/i, '') + '/'
    const getIntlLabel = useIntlLabel()

    // Open the dropdown menu
    const handleOpenMenu = () => {
        setOpenMenu((prevOpen) => !prevOpen)
    }

    const { strapiGlobal } = useStaticQuery<HeaderGlobal>(
        graphql`
            query HeaderGlobal {
                strapiGlobal {
                    siteName
                    strapiId
                    globalTitle
                    globalTitle_mg
                    siteDescription
                    siteDescription_mg
                    siteUsingLabel
                    siteUsingLabel_mg
                    urlGov_mg
                    siteLogo {
                        localFile {
                            publicURL
                            id
                        }
                        id
                    }
                    ChooseLangDialog {
                        availableLang {
                            label
                            code
                            flag {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                    prmLogo {
                        localFile {
                            publicURL
                            id
                        }
                        id
                    }
                    navMenus {
                        url
                        label_mg
                        label
                        id
                        isExternal
                    }
                    headerAlertBar {
                        show
                        message
                        variant
                        actionLabel
                        actionLabel_mg
                        version
                        version_mg
                        urlGov
                        urlGov_mg
                    }
                }
            }
        `,
    )

    const handleScroll = () => {
        setSticky(!(window.scrollY < 72))
    }

    function test(firstValue: boolean, secondValue: String) {
        if (firstValue == true) {
            {
                secondValue
            }
        }
    }

    const changeLanguage = (langCode = 'fr') => {
        changeLocale(langCode)
        localStorage.setItem('prefered-lang', langCode)
        setPreferedLanguage(localStorage.getItem('prefered-lang'))
    }

    useEffect(() => {
        //window.addEventListener('scroll', handleScroll)
        setPreferedLanguage(localStorage.getItem('prefered-lang'))
    }, [])

    const lang = strapiGlobal?.ChooseLangDialog?.availableLang?.find(
        (lang) => lang?.code != preferedLanguage,
    )

    return (
        <HeaderStyled isSticky={false}>
            <Container>
                <ToolbarStyled>
                    <Link to={'/'}>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            <ImageStyled
                                src={strapiGlobal?.prmLogo?.localFile?.publicURL ?? ''}
                                alt="prmLogo"
                                css={{ minWidth: 119, height: 64 }}
                            />
                            <ImageStyled src={Logo} alt="Torolalana" css={{ minWidth: 119 }} />
                        </div>
                    </Link>
                    <MenuWrapper>
                        {strapiGlobal?.navMenus?.map?.((menuItem) => (
                            <MenuListElement key={menuItem?.id}>
                                <Link
                                    to={`${menuItem?.url ?? '/'}`}
                                    css={[
                                        menuItemStyle,
                                        currentRoute.includes(menuItem?.url ?? '/') &&
                                            menuItemActiveStyle,
                                    ]}
                                >
                                    {getIntlLabel(menuItem, 'isExternal') === 'true'
                                        ? getIntlLabel(menuItem, 'label')
                                        : ' '}
                                </Link>
                            </MenuListElement>
                        ))}
                    </MenuWrapper>

                    <LangFlagWrapper onClick={() => changeLanguage(lang?.code!!)}>
                        <span>{lang?.code?.toLocaleUpperCase()}</span>
                        <LangFlag
                            src={lang?.flag?.localFile?.publicURL!!}
                            width={22}
                            height={16}
                            alt="Flag"
                        />
                    </LangFlagWrapper>

                    <MenuToggle openMenu={openMenu} toggle={handleOpenMenu} />
                </ToolbarStyled>

                <motion.nav animate={openMenu ? 'open' : 'closed'} variants={variants}>
                    <MobileToolbarStyled isOpen={openMenu}>
                        {strapiGlobal?.navMenus?.map?.((menuItem) => (
                            <MenuListElement key={menuItem?.id}>
                                <Link
                                    to={`${menuItem?.url ?? '/'}`}
                                    css={[
                                        menuItemStyle,
                                        currentRoute.includes(menuItem?.url ?? '/') &&
                                            menuItemActiveStyle,
                                    ]}
                                >
                                    {getIntlLabel(menuItem, 'label')}
                                </Link>
                            </MenuListElement>
                        ))}
                    </MobileToolbarStyled>
                </motion.nav>
            </Container>
        </HeaderStyled>
    )
}

export default Header
