import axios from 'axios'
import { Link, graphql, navigate, useStaticQuery } from 'gatsby'
import { usePostHog } from 'posthog-js/react'
import { FC, useEffect, useState } from 'react'
import { FiSearch } from 'react-icons/fi'

import { buildQueryParam, capitalize } from '../../helpers'
import { useIntlLabel } from '../../intl'
import { QuerySuggestionType, SearchItemType } from '../../types'
import Container from '../Container'
import {
    SearchButton,
    SearchForm,
    SearchInput,
    SearchResult,
    SearchResultCard,
    SearchResultList,
    SearchStyled,
} from './styles'

const Search: FC = ({}) => {
    var abortController: AbortController

    const [searchQuery, setSearchQuery] = useState<string>('')
    const [searchResults, setSearchResults] = useState<SearchItemType[]>([])
    const [searchSuggestions, setSearchSuggestions] = useState<QuerySuggestionType[]>([])
    const [isSearching, setSearching] = useState<boolean>(false)
    const [value, setValue] = useState<SearchItemType[]>([])
    const [fromSuggest, setFromSuggest] = useState<boolean>(false)
    const posthog = usePostHog()

    const getIntlLabel = useIntlLabel()

    const onSearch = (searchQuery: string) => {
        posthog?.capture('search', {
            query: searchQuery,
        })

        if (searchQuery.length > 0) navigate(`/searchResult/?query=${searchQuery}`)
    }

    const onChange = (searchQuery: string) => {
        setFromSuggest(false)
        setSearchQuery(searchQuery)
    }

    const { strapiSearchSection } = useStaticQuery(
        graphql`
            query Search {
                strapiSearchSection {
                    buttonLabel
                    buttonLabel_mg
                    inputPlaceholder
                    inputPlaceholder_mg
                    patternImage {
                        localFile {
                            publicURL
                        }
                    }
                    coverImage {
                        localFile {
                            publicURL
                        }
                    }
                }
                strapiService {
                    serviceCategories {
                        categoryName
                        categoryName_mg
                        categorySlug
                    }
                }
            }
        `,
    )

    useEffect(() => {
        abortController = new AbortController()

        if (isSearching && abortController) {
            abortController.abort()
        }
        if (searchQuery && searchQuery.length != 0 && !fromSuggest) {
            runSearch(searchQuery)
        } else {
            setSearchResults([])
            setSearchSuggestions([])
            setSearching(false)
        }
    }, [searchQuery])

    const setSuggestQuery = (suggestQuery: string) => {
        setFromSuggest(true)
        setSearchQuery(suggestQuery)
    }

    const serviceSearchResultList = (searchResult: SearchItemType[]) => {
        return searchResult.filter((item) => item._source.type === 'service' && item._score >= 2)
    }

    const runSearch = async (searchQuery: string) => {
        setSearching(true)

        axios
            .get<SearchItemType[]>(
                `${process.env.GATSBY_STRAPI_API_URL ?? 'http://localhost:1337'}/search`,
                {
                    params: {
                        _q: searchQuery,
                    },
                    signal: abortController.signal,
                },
            )
            .then((response: any) => {
                setSearchResults(response.data.hits.hits)
                setSearchSuggestions(response.data.suggest.query_suggestion[0].options)
                setSearching(false)
                setValue(response.data)
            })
            .catch((reason: any) => {
                setSearchResults([])
                setSearching(false)
            })
    }

    return (
        <Container>
            <SearchStyled>
                <SearchForm
                    onSubmit={(event) => {
                        event.preventDefault()
                        onSearch(searchQuery)
                    }}
                >
                    <SearchInput
                        type={'search'}
                        value={searchQuery}
                        placeholder={getIntlLabel(strapiSearchSection, 'inputPlaceholder')}
                        onChange={(event) => {
                            onChange(event.target.value)
                        }}
                    />
                    <SearchButton
                        disabled={searchQuery.length <= 0}
                        role="button"
                        title="Rechercher un service"
                        aria-label="Rechercher un service"
                    >
                        {isSearching ? <FiSearch /> : <FiSearch />}
                    </SearchButton>
                </SearchForm>

                <SearchResult isOpen={serviceSearchResultList(searchResults).length > 0}>
                    <SearchResultCard>
                        {/*<SearchResultList>
                            {searchSuggestions.map((suggestion, index) => (
                                <li
                                    onClick={() => setSuggestQuery(capitalize(suggestion.text))}
                                    key={`suggestion-${index}`}
                                >
                                    {capitalize(suggestion.text)}
                                </li>
                            ))}
                            </SearchResultList>*/}

                        {/*searchSuggestions.length > 0 ? <hr /> : ''*/}

                        <SearchResultList>
                            {serviceSearchResultList(searchResults).map((serviceItem) => {
                                const query = buildQueryParam({
                                    category: serviceItem._source.itemSlug,
                                })
                                return (
                                    <li key={serviceItem._id}>
                                        <Link to={`${serviceItem._source.path}${query}`}>
                                            {getIntlLabel(serviceItem._source, 'title')}
                                        </Link>
                                    </li>
                                )
                            })}
                        </SearchResultList>
                    </SearchResultCard>
                </SearchResult>
            </SearchStyled>
        </Container>
    )
}

export default Search
