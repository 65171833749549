import styled from '@emotion/styled'

export const FooterStyled = styled.footer({
    //backgroundColor: '#FFFFFF',
})

export const ContentStyled = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(5),
    borderTop: `1px solid ${theme.color.neutral.slate[700]}`,
    color: theme.color.neutral.salt[100],
    '@media (max-width: 768px)': {
        height: 'auto',
        paddingTop: 8,
        paddingBottom: 8,
        flexDirection: 'column-reverse',
        justifyContent: 'space-evenly',
    },
}))

export const CopyrightStyled = styled.span(({ theme }) => ({
    color: theme.color.neutral.salt[100],
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
}))

export const AdiotionalLinkStyled = styled.span(({ theme }) => ({
    color: theme.color.neutral.salt[100],
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px',
    '@media (max-width: 768px)': {
        paddingBottom: 8,
    },
}))

export const ContainerStyled = styled.div(({ theme }) => ({
    backgroundColor: theme.color.brand.secondary[900],
    padding: theme.spacing(6),
    color: 'white',
    borderRadius: theme.spacing(4),
    marginBottom: theme.spacing(4),
}))

export const ListContainerStyled = styled.div({
    display: 'flex',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
})

export const ImageStyled = styled.img((props) => ({
    width: 120,

    [props.theme.breakpoints.down(768)]: {
        marginTop: 20,
    },
}))

export const ContainerImagePropulsed = styled.div((props) => ({
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'flex-end',
    [props.theme.breakpoints.down(800)]: {
        marginTop: 20,
        justifyContent: 'center',
    },
}))

export const ImagePropulsedStyled = styled.img((props) => ({
    width: 140,
    marginBottom: 0,
    [props.theme.breakpoints.down(900)]: {
        marginLeft: 0,
    },
}))

export const FooterSectionStyled = styled.div((props) => ({
    //marginBottom: props.theme.spacing(4),
    margin: props.theme.spacing(4, 10, 5, 0),
}))

export const FooterSectionItemStyled = styled.a((props) => ({
    display: 'block',
    color: props.theme.color.neutral.salt[60],
    textDecoration: 'none',
    fontSize: props.theme.spacing(1.9),
    marginBottom: props.theme.spacing(0.6),
    /*fontFamily: props.theme.fonts.body,
    fontWeight: 400,*/
}))

export const FooterSectionTitleStyled = styled.p((props) => ({
    fontSize: props.theme.spacing(2),
}))
