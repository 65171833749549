import { graphql, useStaticQuery } from 'gatsby'
import { changeLocale } from 'gatsby-plugin-react-intl'
import { FC, useEffect, useState } from 'react'
import { MdOutlineTrendingUp } from 'react-icons/md'

import { ButtonStyled } from '../common/Button/styles'
import { Selector } from './__generated__/Selector'
import {
    LangModal,
    LangModalBackDrop,
    LangModalBody,
    LangModalButtonGroup,
    LangModalTitleFr,
    LangModalTitleMg,
} from './styles'

const LangSelector: FC = () => {
    const [isShown, setIsShown] = useState(false)
    const [hasPreferedLanguage, setHasPreferedLanguage] = useState(false)

    const changeLangage = (langCode = 'fr') => {
        changeLocale(langCode)
        setIsShown(!isShown)
        localStorage.setItem('prefered-lang', langCode)
    }

    const { strapiGlobal } = useStaticQuery<Selector>(
        graphql`
            query Selector {
                strapiGlobal {
                    flagLogoMg {
                        localFile {
                            publicURL
                        }
                    }
                    ChooseLangDialog {
                        dialogTitle
                        dialogTitle_mg
                        availableLang {
                            label
                            code
                            flag {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                    }
                }
            }
        `,
    )

    useEffect(() => {
        document.body.classList.toggle('lang-modal-open', isShown)
        if (localStorage.getItem('prefered-lang')) {
            setHasPreferedLanguage(true)
            setIsShown(false)
        } else {
            setIsShown(true)
        }
    }, [isShown])

    const onToggle = () => {
        setIsShown(!isShown)
    }

    return (
        <LangModalBackDrop isOpen={isShown} toggle={onToggle}>
            {!hasPreferedLanguage && (
                <LangModal>
                    <LangModalBody>
                        <LangModalTitleMg>
                            {' '}
                            {strapiGlobal?.ChooseLangDialog?.dialogTitle_mg}{' '}
                        </LangModalTitleMg>
                        <LangModalTitleFr>
                            {strapiGlobal?.ChooseLangDialog?.dialogTitle}{' '}
                        </LangModalTitleFr>
                        <LangModalButtonGroup>
                            {strapiGlobal?.ChooseLangDialog?.availableLang?.map((item: any) => (
                                <ButtonStyled
                                    color="primary"
                                    key={`lang-${item.code}`}
                                    onClick={() => changeLangage(item?.code ?? 'fr')}
                                >
                                    <img
                                        style={{
                                            width: 33,
                                            height: 18,
                                            padding: 0,
                                            left: 66.67,
                                            alignItems: 'center',
                                            marginBottom: '0',
                                            paddingInline: 5,
                                        }}
                                        src={item?.flag?.localFile?.publicURL ?? ''}
                                    ></img>
                                    {item?.label}
                                </ButtonStyled>
                            ))}
                        </LangModalButtonGroup>
                    </LangModalBody>
                </LangModal>
            )}
        </LangModalBackDrop>
    )
}

export default LangSelector
