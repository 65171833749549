import styled from '@emotion/styled'

interface IModalProps {
    isOpen: boolean
    toggle: () => void
}

export const LangModalBackDrop = styled.div<IModalProps>((props) => ({
    display: props.isOpen ? 'flex' : 'none',
    position: 'fixed',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    background: props.theme.color.neutral.pepper[80],
    zIndex: 9999999999,
}))

const LangModalTitle = styled.p((props) => ({}))
export const LangModalTitleMg = styled(LangModalTitle)((props) => ({
    fontSize: props.theme.spacing(3),
    fontWeight: 600,
    textAlign: 'center',
    width: '80%',
    margin: 'auto',
    marginBottom: props.theme.spacing(2),
}))
export const LangModalTitleFr = styled(LangModalTitle)((props) => ({
    textAlign: 'center',
}))

export const LangModalButtonGroup = styled.div((props) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    a: {
        display: 'inline-block',
        margin: `0 ${props.theme.spacing(1)}`,
    },
}))

export const LangModal = styled.div((props) => ({
    background: props.theme.color.neutral.salt[100],
    borderRadius: props.theme.spacing(0.5),
    padding: props.theme.spacing(6),
    maxWidth: '490px',
}))

export const LangModalBody = styled.div((props) => ({}))
