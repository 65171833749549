import * as React from 'react'

import { useIntlLabel } from '../../intl'
import {
    FooterGlobal_allStrapiServiceCategory_nodes,
    FooterGlobal_strapiGlobal_footerLinks,
} from './__generated__/FooterGlobal'
import { FooterSectionItemStyled, FooterSectionStyled, FooterSectionTitleStyled } from './styles'

interface FooterSectionProps {
    footerLink: FooterGlobal_strapiGlobal_footerLinks | null
    categoryLink: FooterGlobal_allStrapiServiceCategory_nodes[]
}

const FooterSection: React.FC<FooterSectionProps> = ({ footerLink, categoryLink }) => {
    const getIntlLabel = useIntlLabel()

    return (
        <FooterSectionStyled>
            <FooterSectionTitleStyled>{getIntlLabel(footerLink, 'title')}</FooterSectionTitleStyled>
            {footerLink?.links?.map((link) => (
                <FooterSectionItemStyled
                    key={link?.label}
                    href={link?.url ?? '/'}
                    target={link?.isExternal ? '_blank' : '_parent'}
                >
                    <span>{getIntlLabel(link, 'label')}</span>
                </FooterSectionItemStyled>
            ))}

            {/*categoryLink.map((category) => (
                <span>{getIntlLabel(category, 'categoryName')}</span>
            ))*/}
        </FooterSectionStyled>
    )
}

export default FooterSection
