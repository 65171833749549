import styled from '@emotion/styled'
import posthog from 'posthog-js'
import React, { FC } from 'react'
import { Helmet } from 'react-helmet'

import Footer from '../Footer'
import Header from '../Header'
import LangSelector from '../LangSelector'
import Search from '../Search'

interface ILayoutProps {
    isHome?: boolean
}

const RootDivStyled = styled.div((props) => {
    return {
        fontFamily: props.theme.fonts.default,
        backgroundColor: '#eff3f8',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        color: props.theme.color.brand.secondary[900],
    }
})

const ContentDivStyled = styled.div({
    flexGrow: 1,
})

const Layout: FC<ILayoutProps> = ({ children, isHome = false }) => (
    <RootDivStyled>
        <Helmet>
            <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" />
            <link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin="true" />
            <link
                href="https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@500;600;700&display=swap"
                rel="stylesheet"
            />
            <link
                href="https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap"
                rel="stylesheet"
            ></link>
        </Helmet>
        <Header isHome={isHome} />
        <Search />
        <ContentDivStyled>{children}</ContentDivStyled>
        <Footer />
        <LangSelector />
    </RootDivStyled>
)

export default Layout
