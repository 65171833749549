import styled from '@emotion/styled'

type SearchStyled = {
    fullWidth?: boolean
}

export const SearchStyled = styled.div((props) => ({
    /*borderBottom: 'solid 1px #cfd8dc',*/
    padding: `${props.theme.spacing(4)} 0`,
    position: 'relative',
    fontFamily: props.theme.fonts.default,
}))

export const SearchForm = styled.form((props) => ({
    display: 'flex',
    margin: '0 auto',
    width: '70%',
    background: props.theme.color.neutral.salt[100],
    borderRadius: props.theme.spacing(6),
    padding: props.theme.spacing(1),
    boxShadow: 'rgb(0 0 0 / 1%) 0px 20px 25px, rgb(0 0 0 / 1%) 0px 10px 10px',
    boxSizing: 'border-box',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        width: '100%',
    },
}))

export const SearchInput = styled.input((props) => ({
    appearance: 'initial',
    flexGrow: 1,
    display: 'flex',
    padding: `0 ${props.theme.spacing(4)}`,
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '16px',
    //border: `solid 1px ${props.theme.color.brand.secondary[700]}`,
    border: 'none',
    borderRadius: props.theme.spacing(5),
    backgroundColor: props.theme.color.neutral.salt[100],
    height: props.theme.spacing(9),
    marginRight: props.theme.spacing(1),
    color: '#0D374D',
    fontFamily: props.theme.fonts.default,

    '&::placeholder': {
        opacity: 0.3,
    },
    '&:focus': {
        outline: 'none',
        border: 'none',
    },
    [props.theme.breakpoints.down(props.theme.breakpoints.small)]: {
        width: 'inherit',
    },
}))

export const SearchButton = styled.button((props) => ({
    cursor: 'pointer',
    backgroundColor: props.theme.color.brand.primary[900],
    color: '#FFFFFF',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: props.theme.spacing(9),
    borderRadius: props.theme.spacing(6),
    transition: 'all ease 300ms',

    '&:disabled': {
        //backgroundColor: props.theme.color.neutral.slate[200],

        '&:hover': {
            svg: {
                transform: 'scale(1)',
                transition: 'all ease 300ms',
            },
        },
    },

    svg: {
        fontSize: props.theme.spacing(3),
        transform: 'scale(1)',
        transition: 'all ease 300ms',
    },

    '&:hover': {
        svg: {
            transform: 'scale(1.2)',
            transition: 'all ease 300ms',
        },
    },
}))

type ISearchResultProps = {
    isOpen: boolean
}

export const SearchResult = styled.div<ISearchResultProps>((props) => ({
    display: props.isOpen ? 'initial' : 'none',
    borderRadius: props.theme.spacing(0.5),
    position: 'absolute',
    width: '100%',
    zIndex: 9999,
    marginTop: props.theme.spacing(1),
}))

export const SearchResultCard = styled.div((props) => ({
    background: '#FFFFFF',
    //border: 'solid 1px #CFD8DC',
    width: '70%',
    margin: 'auto',
    padding: props.theme.spacing(4),
    boxShadow: 'rgb(0 0 0 / 1%) 0px 20px 25px, rgb(0 0 0 / 1%) 0px 10px 10px',
    borderRadius: props.theme.spacing(4),
    boxSizing: 'border-box',

    [props.theme.breakpoints.down(props.theme.breakpoints.md)]: {
        width: '100%',
    },

    hr: {
        margin: `${props.theme.spacing(3)} 0`,
        color: `${props.theme.color.neutral.salt[60]}`,
    },
}))

export const SearchResultList = styled.ul((props) => ({
    listStyle: 'none',
    margin: 0,
    padding: 0,

    li: {
        cursor: 'pointer',
        padding: props.theme.spacing(1, 0),

        '&:last-child': {
            marginBottom: 0,
        },

        a: {
            textDecoration: 'none',
        },
    },
}))
